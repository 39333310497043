// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

//material-dashboard
@import "material-dashboard";

//custom
@import "admin_custom";
